import { minLength, maxLength, required } from '@vuelidate/validators'

import { contractStartDate } from '@/validations/components'
import { ValidatorsUtil } from '@/utils/Validators'

export default {
  address: {
    zipcode: required,
    location: ValidatorsUtil.isLocation,
  },
  contractNumber: {},
  contractStartDate: {
    ...contractStartDate,
  },
  persons: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(7),
    duplicatedPersons: ValidatorsUtil.hasNoDuplicatedPersons,
  },
  salesPartnerEmail: {
    email: ValidatorsUtil.isMail,
  },
}
